<template>
  <div class="preparing-analysis pt-4 w-100">
    <custom-scrollbar>
      <b-container fluid>
        <div class="h3 mb-4">
          Подготовка к анализу
        </div>
        <div class="d-flex justify-content-start align-items-center">
          <b-input-group
            class="w-50 bg-white border rounded"
            :class="[inputFocused ? 'border-primary' : '']"
          >
            <b-input
              v-model="query"
              placeholder="Введите название подготовки"
              class="border-0"
              @keyup.native.enter="fetchPreparation"
              @focus="inputFocused = true"
              @blur="inputFocused = false"
            />
            <b-button
              variant="outline-primary"
              class="rounded-0 border-0"
              :type="$const.PRIMARY_BUTTON"
              @click="fetchPreparation"
            >
              <b-icon icon="search" />
            </b-button>
          </b-input-group>
          <b-button
            class="ml-auto"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            @click="openAddModal"
          >
            Добавить подготовку
          </b-button>
        </div>
        <b-overlay
          v-if="!preparation.totalCount"
          :show="isLoading"
          no-wrap
        />
        <div v-if="preparation.totalCount">
          <b-table
            :fields="fields"
            :items="preparation.data"
            :busy="isLoading"
            :per-page="0"
            :current-page="currentPage"
            :filter="filter"
            class="bg-white crm-table mt-4"
            bordered
            hover
            :small="true"
          >
            <template #cell(logoUrl)="row">
              <img
                v-if="row.item.logoUrl"
                :src="row.item.logoUrl"
                alt="icon"
                class="preparing-analysis__icon"
              >
              <span v-else>Нет иконки</span>
            </template>
            <template #cell(actions)="row">
              <div class="flex px-3 gap-4">
                <b-button
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  title="Редактировать"
                  @click="openEditModal(row)"
                >
                  <b-icon
                    icon="pencil-fill"
                  />
                </b-button>
                <b-button
                  variant="danger"
                  size="sm"
                  title="Удалить"
                  :type="$const.PRIMARY_BUTTON"
                  @click="openDeleteModal(row)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2" />
                <strong>Загрузка...</strong>
              </div>
            </template>
          </b-table>
          <div
            class="mt-3"
          >
            <div
              v-show="preparation.totalCount"
              class="crm-pagination"
            >
              <b-dropdown
                id="dropdown-1"
                :text="`${perPage}`"
                split
                class="m-md-2"
                size="sm"
                split-variant="outline-primary"
                variant="primary"
                :type="$const.PRIMARY_BUTTON"
              >
                <b-dropdown-item @click="tableSizeHandler(20)">
                  20
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="tableSizeHandler(35)">
                  35
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item @click="tableSizeHandler(50)">
                  50
                </b-dropdown-item>
              </b-dropdown>
              <b-pagination
                v-model="currentPage"
                pills
                :total-rows="preparation.totalCount"
                :per-page="perPage"
                class="pt-3"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="mt-3"
        >
          <span>Ничего не найдено</span>
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { laboratoryService } from '@/services';

export default {
  name: 'PreparingAnalysis',
  page: {
    title: 'CRM Doctis - Подготовка к анализу',
  },
  data() {
    return {
      preparation: {
        data: [],
        totalCount: 0,
      },
      inputFocused: false,
      isLoading: false,
      query: '',
      currentPage: 1,
      perPage: 20,
      filter: null,
      fields: [
        {
          key: 'logoUrl',
          label: 'Иконка',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'text',
          label: 'Название',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
          class: 'td-button w-5',
        },
      ],
    };
  },
  methods: {
    async fetchPreparation() {
      this.isLoading = true;
      const { data, totalCount } = await laboratoryService.getPreparationList({
        text: this.query,
        limit: this.perPage,
        offset: (this.currentPage - 1) * this.perPage,
      });
      this.preparation.data = [...data];
      this.preparation.totalCount = totalCount;
      this.isLoading = false;
    },
    openAddModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PreparingAnalysisCreateModal',
        props: {
          afterSave: () => this.fetchPreparation(),
        },
      });
    },
    openEditModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PreparingAnalysisEditModal',
        props: {
          preparationId: row.item.id,
          afterSave: () => this.fetchPreparation(),
        },
      });
    },
    openDeleteModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PreparingAnalysisDeleteModal',
        props: {
          preparationId: row.item.id,
          afterDelete: () => this.fetchPreparation(),
        },
      });
    },
    async tableSizeHandler(mount) {
      this.perPage = mount;
    },
  },
  watch: {
    perPage() {
      this.fetchPreparation();
    },
    currentPage() {
      this.fetchPreparation();
    },
  },
  async created() {
    await this.fetchPreparation();
  },
};
</script>

<style lang="scss" scoped>
  .preparing-analysis {
    max-width: calc(100% - 250px);
    padding: 0 3rem;

    &__icon {
      display: block;
      width: 80px;
      height: 80px;
      margin: auto;
    }
  }
</style>
